import { Button, Flex, Result } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';

export const NoAccess = () => {
  return (
    <Flex justify="center">
      <Result
        className={css`
          max-width: 800px;
        `}
        status={403}
        title="Laborbefunde"
        subTitle="Es tut uns leid, aber Sie haben derzeit keinen Zugriff auf diesen Bereich von labcomplete®. Um Befundinformationen anzeigen zu können, benötigen Sie spezielle Berechtigungen.  Bitte wenden Sie sich an Ihr Labor, um diese zu erhalten."
        extra={
          <Button icon={<ReloadOutlined />} onClick={() => (window.location.href = '/')} type="primary">
            Applikation neu laden
          </Button>
        }
      />
    </Flex>
  );
};
