import React, { useState } from 'react';
import { Alert, Button, Col, ColorPicker, Form, FormInstance, Input, Row, Typography, Upload } from 'antd';
import { Color } from 'antd/es/color-picker';
import { css } from '@emotion/css';
import logo from '../../../assets/logo.svg';
import { blobToDataUrl } from '../../../utils/helpers.ts';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { ThemeCreateRequest } from '../../../generated/openapi/befunde-api';

export const ThemeForm: React.FC<{
  form: FormInstance<ThemeCreateRequest>;
  onFinish: (values: ThemeCreateRequest) => void;
  initialValues?: Partial<ThemeCreateRequest>;
}> = ({ form, onFinish, initialValues }) => {
  const [fileError, setFileError] = useState('');

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={() => onFinish(form.getFieldsValue(true))}
      initialValues={initialValues}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
        <Input autoFocus />
      </Form.Item>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item name="colorPrimary" label="Primärfarbe" getValueFromEvent={(a: Color) => a.toHexString()}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="controlItemBgActive" label="BG Aktiv" getValueFromEvent={(a: Color) => a.toHexString()}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="controlItemBgActiveHover" label="BG Hover" getValueFromEvent={(a: Color) => a.toHexString()}>
            <ColorPicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Upload
            showUploadList={false}
            maxCount={1}
            beforeUpload={file => {
              return new Promise((resolve, reject) => {
                if (file.size > 80000 /* 80 KB */) {
                  setFileError('Logo darf nicht größer als 80KB sein');
                  reject();
                  return;
                }

                if (
                  file.type !== 'image/jpeg' &&
                  file.type !== 'image/png' &&
                  file.type !== 'image/jpg' &&
                  file.type !== 'image/svg' &&
                  file.type !== 'image/svg+xml'
                ) {
                  setFileError('Logo ist kein JPG, PNG oder SVG');
                  reject();
                  return;
                }

                setFileError('');
                resolve();
              });
            }}
            customRequest={async info => {
              const dataUrl = await blobToDataUrl(info.file as Blob);
              form.setFieldValue('logoData', dataUrl);
            }}
          >
            <Button icon={<CloudUploadOutlined />}>Logo hochladen</Button>
          </Upload>
          {fileError && (
            <div>
              <Typography.Text type="danger">{fileError}</Typography.Text>
            </div>
          )}
        </Col>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => (
            <>
              <Col span={8}>
                <img
                  src={getFieldValue('logoData') ? getFieldValue('logoData') : logo}
                  alt="Logo Preview"
                  className={css`
                    width: 200px;
                  `}
                />
              </Col>
              <Col span={8}>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  hidden={!getFieldValue('logoData')}
                  onClick={() => setFieldValue('logoData', '')}
                >
                  Logo löschen
                </Button>
              </Col>
            </>
          )}
        </Form.Item>
      </Row>
      <p />
      <Alert
        type="info"
        showIcon
        message={
          <ul style={{ margin: 0 }}>
            <li>Das Logo muss ein .jpg, .png oder .svg sein</li>
            <li>Empfohlenes Format: SVG</li>
            <li>Empfohlene Auflösung: 200x30</li>
            <li>Max. Dateigröße: 80KB</li>
          </ul>
        }
      />
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
