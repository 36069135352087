import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

const firstBreak = '900px';
const secondBreak = '700px';

export const useGridClassNames = () => {
  const { token } = theme.useToken();

  const containerName = 'observation-result-row';

  return useMemo(() => {
    const detailsClassName = css`
      overflow: hidden;
      margin-bottom: ${token.marginLG}px;
      container-name: ${containerName};
      container-type: inline-size;
      background: ${token.colorBgContainer};
    `;
    const emphasizedClassName = css`
      color: ${token.colorError};
      font-weight: bold;
    `;
    const headerBorderClassName = css`
      padding-bottom: ${token.paddingSM}px;
      border-bottom: 1px solid ${token.colorBorder};
    `;
    const entryBorderClassName = css`
      padding-bottom: ${token.paddingSM}px;
      border-bottom: 1px dashed ${token.colorBorder};
    `;
    const headerClassName = css`
      grid-column: 1 / span 6;
      margin-bottom: 0;
      font-size: 1.3em;
    `;
    const subheaderClassName = css`
      color: ${token.colorTextDisabled};
      font-size: ${token.fontSizeSM}px;
      font-weight: bold;
      text-transform: uppercase;
    `;
    const accreditationClassName = css`
      grid-area: accreditation;
      width: 100%;
    `;
    const specimenClassName = css`
      grid-column: 2 / span 5;
    `;
    const parameterClassName = css`
      grid-area: parameter;
    `;
    const resultClassName = css`
      grid-area: result;
      @container ${containerName} (width < ${secondBreak}) {
        & {
          text-align: right;
        }
      }
    `;
    const pathologicalClassName = css`
      grid-area: pathological;
      @container ${containerName} (width < ${secondBreak}) {
        & {
          text-align: right;
        }
      }
    `;
    const unitClassName = css`
      grid-area: unit;
      @container ${containerName} (width < ${secondBreak}) {
        & {
          text-align: right;
        }
      }
    `;
    const rangeClassName = css`
      grid-area: range;
      display: flex;
      @container ${containerName} (width < ${secondBreak}) {
        & {
          justify-content: end;
        }
      }
    `;
    const graphicClassName = css`
      grid-area: graphic;
      display: none;
      @container ${containerName} (width >= ${firstBreak}) {
        & {
          display: block;
        }
      }
    `;
    const popupGraphicClassName = css`
      grid-area: popupgraphic;
      display: none;
      margin-right: 5px;
      @container ${containerName} (width < ${firstBreak}) {
        & {
          display: flex;
          justify-content: end;
        }
      }
      @container ${containerName} (width < ${secondBreak}) {
        & {
          margin-right: 0;
        }
      }
    `;
    const stickyClassName = (index: number, top: boolean) => css`
      position: sticky;
      ${top ? 'top: 0;' : ''}
      z-index: ${top ? index + 1 : index};
      background: ${token.colorBgContainer};
      padding-bottom: ${token.paddingSM}px;
    `;
    const commentClassName = css`
      grid-area: parameter;
      grid-column: 2 / span 5;
      position: relative;
      overflow-x: auto;

      & span {
        position: absolute;
        top: 2px;
        color: ${token.colorInfo};
      }

      & pre {
        color: ${token.colorInfo};
        margin-left: 24px;
        font-size: 12px;
      }
    `;
    const gridClassName = (index: number) => css`
      display: grid;
      // prettier-ignore
      grid-template-columns: 20px minmax(200px, 3fr) minmax(120px, 1fr) minmax(30px, 1fr) minmax(80px, 1fr) minmax(130px, 1fr) 200px;
      grid-template-areas: 'accreditation parameter result pathological unit range graphic';
      gap: ${token.padding}px;
      position: sticky;
      z-index: ${index};
      width: 100%;
      padding-top: ${token.marginSM}px;
      background: ${token.colorBgContainer};

      @container ${containerName} (width < ${firstBreak}) {
        // prettier-ignore
        grid-template-columns: 20px minmax(150px, 3fr) minmax(120px, 1fr) minmax(30px, 1fr) minmax(80px, 1fr) minmax(130px, 1fr) 30px;
        grid-template-areas: 'accreditation parameter result pathological unit range popupgraphic';
      }
      @container ${containerName} (width < ${secondBreak}) {
        & {
          grid-template-columns: 20px auto auto 10px;
          grid-template-areas:
            'accreditation parameter result space'
            '. . pathological .'
            '. . unit .'
            '. . range .'
            '. . popupgraphic .';
          gap: 0;
        }
      }
    `;
    return {
      containerName,
      detailsClassName,
      headerBorderClassName,
      entryBorderClassName,
      emphasizedClassName,
      gridClassName,
      headerClassName,
      subheaderClassName,
      accreditationClassName,
      specimenClassName,
      parameterClassName,
      resultClassName,
      pathologicalClassName,
      unitClassName,
      rangeClassName,
      graphicClassName,
      popupGraphicClassName,
      commentClassName,
      stickyClassName,
    };
  }, [token]);
};
