import { AccountAuthz } from '../generated/openapi/befunde-api';

export const currentUrlWithoutQueryString = () => window.location.href.split('?')[0];

export const buildAuthzInfo = (authz: AccountAuthz[]): string => {
  return authz.map(it => `${it.labId}: ${it.identifiers.join(', ')}`).join(' - ');
};

export const blobToDataUrl = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
