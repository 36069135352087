import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  Alert,
  App,
  Button,
  Flex,
  Input,
  Popconfirm,
  Select,
  Table,
  TablePaginationConfig,
  Tag,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Markable } from '../../components/Markable';
import { Account, User } from '../../generated/openapi/befunde-api';
import { PageHeader } from '@ant-design/pro-components';
import { useDeleteUserAccount, useSearchUsers } from '../../api/user.queries.ts';
import { css } from '@emotion/css';
import { useGetAllAccounts } from '../../api/account.queries.ts';
import { tableActionCell } from '../../styles/globalCss.ts';
import {
  CheckOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  ReloadOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { UpdateUserModal } from './users/UpdateUserModal.tsx';
import { useGetUserInfo } from '../../api/user-info.queries.ts';
import { buildAuthzInfo } from '../../utils/helpers.ts';

type UserWithAccountName = User & { accountName?: string; accountDisabled?: boolean };

const { Option } = Select;
const DEFAULT_PAGE_SIZE = 10;

const useUsersClassNames = () => {
  const { token } = theme.useToken();
  return {
    pageWrapper: css`
      padding: ${token.paddingLG}px;
    `,
  };
};

export const Users: React.FC = () => {
  const [editUser, setEditUser] = useState<User | null>(null);
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [debouncedSearch] = useDebounce(search, 500);
  const [pageable, setPageable] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const { token } = theme.useToken();
  const { message } = App.useApp();
  const styles = useUsersClassNames();

  const {
    data: usersData,
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: usersRefetch,
  } = useSearchUsers({
    page: pageable.page,
    pageSize: pageable.pageSize,
    accountId: selectedAccountId,
    search: debouncedSearch,
  });

  const { data: accountsData, isLoading: accountsLoading, isFetching: accountsFetching } = useGetAllAccounts();
  const { mutate: deleteAccount } = useDeleteUserAccount();
  const { refetch: refetchUserInfo } = useGetUserInfo();

  const deleteUserAccount = (id: string) => {
    deleteAccount(
      { id },
      {
        onSuccess: () => {
          message.success('Beziehung wurde aufgehoben');
          usersRefetch();
          refetchUserInfo();
        },
        onError: e => {
          message.error('Beziehung konnte nicht aufgehoben werden: ' + e);
        },
      }
    );
  };

  const usersWithAccount = useMemo<UserWithAccountName[]>(() => {
    if (usersData && accountsData) {
      const accountsMap = accountsData.items.reduce<Map<string, Account>>((map, acc) => {
        map.set(acc.id, acc);
        return map;
      }, new Map());

      return usersData.items.map(it => ({
        ...it,
        accountName: it.accountId ? accountsMap.get(it.accountId)?.name : undefined,
        accountDisabled: it.accountId ? accountsMap.get(it.accountId)?.disabled : undefined,
      }));
    }
    return [];
  }, [usersData, accountsData]);

  const columns: ColumnsType<UserWithAccountName> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: value => (
        <Typography.Text copyable code>
          {value}
        </Typography.Text>
      ),
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Aktiv',
      dataIndex: 'disabled',
      key: 'disabled',
      width: 70,
      render: value => (!value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Benutzername',
      dataIndex: 'username',
      key: 'username',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Vorname',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Nachname',
      dataIndex: 'lastName',
      key: 'lastName',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 100,
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Account',
      dataIndex: 'accountName',
      key: 'accountName',
      width: 120,
      ellipsis: true,
      render: (_, record) =>
        record.accountName ? (
          <Tag
            color={record.accountDisabled ? 'warning' : undefined}
            icon={record.accountDisabled ? <WarningOutlined /> : undefined}
          >
            {record.accountDisabled ? `Deakt. - ${record.accountName}` : record.accountName}
          </Tag>
        ) : (
          ''
        ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => (
        <>
          <Button
            icon={<LinkOutlined />}
            type="text"
            disabled={accountsLoading || accountsFetching}
            onClick={() => setEditUser(record)}
          />
          <Popconfirm
            title="Beziehung aufheben?"
            onConfirm={() => deleteUserAccount(record.id)}
            okText="Ja"
            okButtonProps={{ danger: true }}
            cancelText="Nein"
            placement="top"
            disabled={accountsLoading || accountsFetching || !record.accountId}
          >
            <Button
              icon={<LinkOutlined />}
              type="text"
              danger
              disabled={accountsLoading || accountsFetching || !record.accountId}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const page = pagination.current || 1;
    const pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

    setPageable({
      page: page,
      pageSize: pageSize,
    });
  };

  return (
    <div className={styles.pageWrapper}>
      <PageHeader
        title="Benutzer"
        extra={
          <Flex wrap gap={token.paddingMD}>
            <Input
              allowClear
              autoFocus
              placeholder="Suche"
              value={search || ''}
              onChange={e => {
                setSelectedAccountId(undefined);
                setPageable(current => ({ ...current, page: 1 })); // reset page
                setSearch(e.target.value);
              }}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Benutzername, Vorname, Nachname oder E-Mail. Mind 3 Zeichen eingeben!">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              style={{ width: '250px' }}
            />
            <Select
              style={{ width: '350px' }}
              onChange={value => {
                setSelectedAccountId(value as string);
                setPageable(current => ({ ...current, page: 1 })); // reset page
                setSearch(undefined);
              }}
              value={selectedAccountId}
              showSearch
              allowClear
              placeholder="Suche Account nach Name, Laborkennung und Identifikator"
              optionFilterProp="search"
              optionLabelProp="taglabel"
            >
              {[...(accountsData?.items ?? [])]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(account => {
                  return (
                    <Option
                      key={account.id}
                      value={account.id}
                      taglabel={account.name}
                      search={`${account.name} ${account.authz.map(it => it.labId).join(' ')} ${account.authz.map(it => it.identifiers.join(' ')).join(' ')}`}
                    >
                      <div>{account.name}</div>
                      <Typography.Text type="secondary">{buildAuthzInfo(account.authz)}</Typography.Text>
                    </Option>
                  );
                })}
            </Select>
            <Button icon={<ReloadOutlined />} onClick={() => usersRefetch()} />
          </Flex>
        }
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Alert
        message="Hier können die Beziehungen von Benutzern zu deren Accounts festgelegt werden. Die Benutzerverwaltung ist in Keycloak durchzuführen."
        type="info"
        showIcon
      />
      <p />
      <Table<UserWithAccountName>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={usersWithAccount}
        pagination={{
          current: usersData ? Math.floor(usersData.start / usersData.limit + 1) : 1,
          total: usersData?.total ?? 0,
          showTotal: total =>
            `${(usersData?.start ?? 0) + 1} bis ${
              (usersData?.start ?? 0) + (usersData?.length ?? 0)
            } von ${total} Benutzern`,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        loading={usersLoading || usersFetching}
        columns={columns}
        onChange={pagination => handleTableChange(pagination)}
      />
      <UpdateUserModal
        user={editUser}
        onClose={() => {
          setEditUser(null);
          usersRefetch();
          refetchUserInfo();
        }}
        accounts={accountsData?.items ?? []}
      />
    </div>
  );
};
