import React, { ReactNode, useEffect, useState } from 'react';
import deDE from 'antd/locale/de_DE';
import { ConfigProvider } from 'antd';
import { useGetUserInfo } from './api/user-info.queries.ts';
import { useGetTheme } from './api/theme.queries.ts';
import { defaultTheme, useThemeStore } from './store/useThemeStore.ts';

export const DynamicConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data: userInfo } = useGetUserInfo();
  const { data: accountTheme } = useGetTheme({
    id: userInfo?.themeId ?? '',
  });
  const { theme, setTheme, previewMode } = useThemeStore();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (!previewMode && theme.id !== accountTheme?.id) {
      setTheme(accountTheme ?? defaultTheme, false);
    }
  }, [accountTheme, theme, setTheme, previewMode]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsSmallScreen]);

  return (
    <ConfigProvider
      locale={deDE}
      theme={{
        cssVar: true,
        token: {
          colorLink: '#4A88CB',
          colorPrimary: theme.colorPrimary,
          fontFamily: 'Outfit, sans-serif',
          fontSize: isSmallScreen ? 14 : 16,
          controlItemBgActive: theme.controlItemBgActive,
          controlItemBgActiveHover: theme.controlItemBgActiveHover,
        },
        components: {
          Typography: {
            fontWeightStrong: 900,
          },
          Layout: {
            headerBg: '#fff',
            bodyBg: '#fff',
            headerHeight: 80,
          },
          Menu: {
            itemBg: '#F8F7F4',
            subMenuItemBg: '#F8F7F4',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
