import React from 'react';
import { Segmented, theme } from 'antd';
import { css } from '@emotion/css';
// import { FileAddOutlined, FileProtectOutlined } from '@ant-design/icons';

export const AppSwitcher: React.FC = () => {
  const { token } = theme.useToken();
  return (
    <Segmented
      className={css`
        margin: 0 ${token.marginLG}px 0 ${token.marginLG}px;
        @media (max-width: ${token.screenMD}px) {
          display: none;
        }
      `}
      size="large"
      defaultValue="Befunde"
      options={[
        { label: 'Anforderungen', value: 'Anforderungen' /*, icon: <FileAddOutlined />*/ },
        { label: 'Befunde', value: 'Befunde' /*, icon: <FileProtectOutlined />*/ },
      ]}
      onChange={value => {
        if (value === 'Anforderungen') {
          window.location.href = window._env_.LABRADOR_URL;
        }
      }}
    />
  );
};
