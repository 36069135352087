import React, { useMemo, useRef } from 'react';
import { ObservationResultRenderer } from './ObservationResultRenderer';
import { Filter } from '../filter/FilterTypes';
import { css, cx } from '@emotion/css';
import { useGridClassNames } from '../OrderResult.styles.ts';
import { Empty } from 'antd';
import { type LabResultObservationResult, LabResultResponse } from '../../../generated/openapi/befunde-api';
import { GenderIcon } from '../ResultDetails.tsx';

type Group = {
  groupName: string;
  results: LabResultObservationResult[];
};

function matchFilter(result: LabResultObservationResult, filter: Filter) {
  if (filter.pathological !== null && (result.pathological || '').length > 0 !== filter.pathological) {
    return false;
  }
  if (filter.tokens.length > 0) {
    const testName = (result.testName || '').toLowerCase();
    return filter.tokens.every(token => testName.includes(token));
  }
  return true;
}

/**
 * Intentionally no hash grouping used so that the order of the results is preserved,
 * regardless of whether a group can occur more than once
 */
const group = (results: LabResultObservationResult[], filter: Filter): Group[] => {
  const groups = [];

  let lastGroup: Group | null = null;
  for (const result of results) {
    if (matchFilter(result, filter)) {
      if (lastGroup === null || lastGroup!.groupName !== result.resultGroup) {
        lastGroup = { groupName: result.resultGroup || '', results: [result] };
        groups.push(lastGroup);
      } else {
        lastGroup.results.push(result);
      }
    }
  }

  return groups;
};

export const ObservationResultsRenderer: React.FC<{
  result: LabResultResponse;
  filter: Filter;
  deemphasizePathological: boolean;
}> = ({ result, filter, deemphasizePathological }) => {
  const {
    gridClassName,
    headerBorderClassName,
    headerClassName,
    subheaderClassName,
    accreditationClassName,
    parameterClassName,
    resultClassName,
    pathologicalClassName,
    unitClassName,
    rangeClassName,
    graphicClassName,
    popupGraphicClassName,
    stickyClassName,
  } = useGridClassNames();

  const headerRef = useRef(null);
  const groups = useMemo(() => group(result.observationResults, filter), [result.observationResults, filter]);

  if (groups.length === 0) {
    return <Empty description="Keine Untersuchung für angegeben Filter gefunden" />;
  }

  return groups.map((group, groupIndex) => {
    return (
      <React.Fragment key={`${group.groupName}${groupIndex}`}>
        <div ref={headerRef} className={cx(gridClassName(groupIndex), stickyClassName(groupIndex, true))}>
          <h3 className={headerClassName}>{group.groupName}</h3>
        </div>
        <div className={stickyClassName(groupIndex, false)}>
          <div className={cx(gridClassName(groupIndex), headerBorderClassName)}>
            <div className={cx(accreditationClassName, subheaderClassName)}></div>
            <div className={cx(parameterClassName, subheaderClassName)}>Untersuchung</div>
            <div className={cx(resultClassName, subheaderClassName)}>Ergebnis</div>
            <div className={cx(pathologicalClassName, subheaderClassName)}></div>
            <div className={cx(unitClassName, subheaderClassName)}>Einheit</div>
            <div className={cx(rangeClassName, subheaderClassName)}>
              Normbereich
              <GenderIcon
                gender={result.patient.gender}
                className={css`
                  font-size: 15px;
                  padding-left: 5px;
                `}
              />
            </div>
            <div className={cx(graphicClassName, subheaderClassName)}></div>
            <div className={cx(popupGraphicClassName, subheaderClassName)}></div>
          </div>
          {group.results.map((result, index) => (
            <ObservationResultRenderer
              key={`${result.testName}${index}`}
              index={groupIndex}
              filter={filter}
              result={result}
              deemphasizePathological={deemphasizePathological}
            />
          ))}
        </div>
      </React.Fragment>
    );
  });
};
