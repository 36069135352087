import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  Configuration,
  Theme,
  ThemeApi,
  ThemeApiCreateThemeRequest,
  ThemeApiGetThemeRequest,
  ThemeApiUpdateThemeRequest,
  ThemeListResponse,
} from '../generated/openapi/befunde-api';
import { loadUser } from '../oidcConfig.ts';

const api = new ThemeApi(
  new Configuration({ accessToken: () => loadUser()?.access_token ?? '' }),
  window._env_.API_URL
);

export const useGetAllThemes = () =>
  useQuery<ThemeListResponse, AxiosError>({
    queryKey: [api.getAllThemes.name],
    queryFn: async () => {
      const res = await api.getAllThemes();
      return res?.data;
    },
    retry: false,
  });

export const getTheme = async (queryClient: QueryClient, params: ThemeApiGetThemeRequest) =>
  await queryClient.fetchQuery({
    queryKey: [api.getTheme.name, params],
    queryFn: async () => {
      const res = await api.getTheme(params);
      return res?.data;
    },
  });

export const useGetTheme = (params: ThemeApiGetThemeRequest) =>
  useQuery<Theme, AxiosError>({
    queryKey: [api.getTheme.name, params],
    queryFn: async () => {
      const res = await api.getTheme(params);
      return res?.data;
    },
    retry: false,
    enabled: !!params.id,
  });

export const useCreateTheme = () =>
  useMutation<AxiosResponse<Theme>, AxiosError, ThemeApiCreateThemeRequest>({
    mutationFn: params => api.createTheme(params),
  });

export const useUpdateTheme = () =>
  useMutation<AxiosResponse<Theme>, AxiosError, ThemeApiUpdateThemeRequest>({
    mutationFn: params => api.updateTheme(params),
  });
