import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Filter } from './FilterTypes.ts';
import { Flex, Input, Tabs, theme } from 'antd';
import { css } from '@emotion/css';
import { useKeyAction } from '../../../hooks/useKeyAction.ts';

const useObservationResultFilterClassNames = () => {
  const { token } = theme.useToken();

  return {
    layoutClassName: css`
      flex-wrap: nowrap;
      width: 100%;
      gap: 0 ${token.paddingLG}px;
      justify-items: right;
      margin-top: ${token.margin}px;
    `,
    inputClassName: css`
      margin: 8px 8px 0 0;
      min-width: 74px;
    `,
    tabsClassName: css`
      flex-grow: 1;
      overflow: hidden;
    `,
  };
};

export const ObservationResultFilter: React.FC<{
  values: number;
  pathological: number;
  attachments: number;
  value: Filter;
  onChange: Dispatch<SetStateAction<Filter>>;
}> = ({ values, pathological, attachments, value, onChange }) => {
  const [filterTokens, setFilterTokens] = useState(value.tokens.join(' '));
  const [focused, setFocused] = useState(false);
  const [key, setKey] = useState<string>(values > 0 ? 'all' : 'attach');

  const { layoutClassName, inputClassName, tabsClassName } = useObservationResultFilterClassNames();

  useEffect(() => {
    onChange(prev => ({
      ...prev,
      tokens: filterTokens
        .trim()
        .split(' ')
        .filter(t => !!t)
        .map(t => t.toLowerCase()),
    }));
  }, [onChange, filterTokens]);

  useEffect(() => {
    onChange(prevState => ({
      ...prevState,
      pathological: key === 'all' ? null : key === 'patho',
    }));
  }, [onChange, key]);

  const action = useMemo(() => {
    if (focused) {
      return () => {
        setFilterTokens('');
      };
    } else {
      return null;
    }
  }, [focused, setFilterTokens]);

  useKeyAction('Escape', action);

  const items = [];
  if (values > 0) {
    items.push({ key: 'all', label: `Alle Werte (${values})` });
    if (pathological > 0) {
      items.push({ key: 'patho', label: `pathologisch (${pathological})` });
      const nonPathoCount = values - pathological;
      if (nonPathoCount > 0) {
        items.push({ key: 'non-patho', label: `nicht pathologisch (${nonPathoCount})` });
      }
    }
  }
  if (attachments > 0) {
    items.push({ key: 'attach', label: `Anhänge (${attachments})` });
  }

  return (
    <Flex className={layoutClassName}>
      <Tabs className={tabsClassName} size="small" activeKey={key} onChange={setKey} items={items} />
      {values > 0 && (
        <div className={inputClassName}>
          <Input
            placeholder="Filter"
            value={filterTokens}
            onChange={e => setFilterTokens(e.target.value)}
            allowClear
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        </div>
      )}
    </Flex>
  );
};
