import React from 'react';
import { App, Button, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ThemeForm } from './ThemeForm.tsx';
import { useForm } from 'antd/es/form/Form';
import { ThemeCreateRequest } from '../../../generated/openapi/befunde-api';
import { defaultTheme } from '../../../store/useThemeStore.ts';
import { useCreateTheme } from '../../../api/theme.queries.ts';

export const CreateThemeModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const [form] = useForm<ThemeCreateRequest>();
  const { message } = App.useApp();
  const { mutate, isPending } = useCreateTheme();

  const create = async (request: ThemeCreateRequest) => {
    mutate(
      {
        themeCreateRequest: request,
      },
      {
        onSuccess: () => {
          message.success('Theme wurde erstellt');
          closeAndReset();
        },
        onError: e => {
          message.error('Theme konnte nicht erstellt werden: ' + e);
        },
      }
    );
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  const { id, ...cleanDefaultTheme } = defaultTheme;

  return (
    <Modal
      width={600}
      title="Neues Theme erstellen"
      open={open}
      destroyOnClose
      onCancel={closeAndReset}
      footer={[
        <Button key="cancel" onClick={closeAndReset}>
          Abbrechen
        </Button>,
        <Button key="submit" icon={<PlusCircleOutlined />} type="primary" loading={isPending} onClick={form.submit}>
          Erstellen
        </Button>,
      ]}
    >
      <ThemeForm
        form={form}
        onFinish={create}
        initialValues={{
          ...cleanDefaultTheme,
          name: '',
        }}
      />
    </Modal>
  );
};
