import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Theme } from '../generated/openapi/befunde-api';

export interface ThemeState {
  theme: Theme;
  previewMode: boolean;
  setTheme: (theme: Theme, previewMode: boolean) => void;
}

export const defaultTheme: Theme = {
  id: 'default',
  name: 'Default Theme',
  colorPrimary: '#2b2b2b',
  controlItemBgActive: '#fff7dc',
  controlItemBgActiveHover: '#ffeeb4',
  logoData: '',
};

export const useThemeStore = create<ThemeState>()(
  devtools(
    set => ({
      theme: defaultTheme,
      previewMode: false,
      setTheme: (theme, previewMode) => set({ theme, previewMode }),
    }),
    { name: 'theme-store' }
  )
);
