import React, { FC } from 'react';
import { css } from '@emotion/css';
import { Collapse, Flex, theme } from 'antd';
import { FemaleIcon } from '../../icons/FemaleIcon.tsx';
import { MaleIcon } from '../../icons/MaleIcon.tsx';
import {
  LabResultObservationRequestStatusEnum,
  LabResultPatient,
  LabResultResponse,
  LabResultsSearchResult,
} from '../../generated/openapi/befunde-api';
import dayjs from 'dayjs';
import { formatDateString, formatDateTimeString } from '../../utils/datetime.ts';

export const GenderIcon: FC<{ gender: string | null; className?: string }> = ({ gender, className }) => {
  switch (gender) {
    case 'M':
      return <MaleIcon className={className} />;
    case 'W':
      return <FemaleIcon className={className} />;
  }
  return null;
};

export const patientFullName = (patient: LabResultPatient | LabResultsSearchResult) => {
  const titleLastName = `${patient.title} ${patient.lastName}`.trim();
  return [titleLastName, patient.firstName].join(', ').trim();
};

const personAge = (birthday: string): number => Math.round(dayjs().diff(birthday, 'years', true) * 10) / 10;

const translateLabResultState = (value: LabResultObservationRequestStatusEnum) => {
  switch (value) {
    case LabResultObservationRequestStatusEnum.InProgress:
      return 'Befund in Arbeit';
    case LabResultObservationRequestStatusEnum.PartialResult:
      return 'Teilbefund';
    case LabResultObservationRequestStatusEnum.EndResult:
      return 'Endergebnis';
    case LabResultObservationRequestStatusEnum.OrderReceived:
      return 'Auftrag empfangen';
    case LabResultObservationRequestStatusEnum.SpecimenReceived:
      return 'Probe empfangen';
  }
};

const DetailsRenderer: React.FC<{
  result: LabResultResponse;
}> = ({ result }) => {
  const { token } = theme.useToken();

  const subheaderClassName = css`
    color: ${token.colorTextDisabled};
    font-size: ${token.fontSizeSM}px;
    font-weight: bold;
    text-transform: uppercase;
  `;

  return (
    <Flex wrap gap={token.paddingLG}>
      <div>
        <div className={subheaderClassName}>Geschlecht</div>
        <div>
          <GenderIcon
            gender={result.patient.gender}
            className={css`
              margin-right: ${token.marginXXS}px;
            `}
          />
          {result.patient.gender}
        </div>
      </div>
      <div>
        <div className={subheaderClassName}>Geburtsdatum</div>
        <div>{formatDateString(result.patient.birthdate)}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Alter</div>
        <div>{personAge(result.patient.birthdate).toLocaleString('de-AT')} J</div>
      </div>
      <div>
        <div className={subheaderClassName}>SVNR</div>
        <div>{result.patient.svnr}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Auftragsnr.</div>
        <div>{result.orderNumber}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Eingangsdatum</div>
        <div>{formatDateTimeString(result.observationRequest.orderEntryDate)}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Status</div>
        <div>{translateLabResultState(result.observationRequest.status)}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Stand</div>
        <div>{formatDateTimeString(result.timestamp)}</div>
      </div>
    </Flex>
  );
};

export const ResultDetails: FC<{ result: LabResultResponse }> = ({ result }) => {
  const { token } = theme.useToken();

  return (
    <Collapse
      // bordered={false}
      defaultActiveKey={window.innerWidth < token.screenMD ? [] : ['details']}
      className={css`
        .ant-collapse-header {
          line-height: 1.5em !important;
        }
        .ant-collapse-header-text {
          font-size: 1.4em;
          font-weight: bold;

          @media (max-width: ${token.screenLG}px) {
            font-size: 1.2em;
          }
        }
      `}
      items={[
        {
          key: 'details',
          label: patientFullName(result.patient),
          children: <DetailsRenderer result={result} />,
        },
      ]}
    />
  );
};
