import { App } from 'antd';
import { useEffect } from 'react';

/** we assume the nativeApi userInfo is already set by labrador as the starting point of the desktop client is always labrador */
export const ImportFileWatcher = () => {
  const { modal } = App.useApp();

  useEffect(() => {
    if (!window.nativeApi) {
      return;
    }

    console.log('registering watcher');
    window.nativeApi.watchImportFile(async () => {
      console.log('import file changed');
      window.nativeApi?.bringToFront();
      modal.confirm({
        maskClosable: true,
        title: 'Patientendaten geladen',
        content: 'Es sind neue Patientendaten verfügbar. Wollen Sie eine Anforderung mit diesen Daten durchführen?',
        onOk: () => {
          window.location.href = window._env_.LABRADOR_URL + '/anforderung?doAisImport=true';
        },
      });
    });

    return () => {
      console.log('un-registering watcher');
      window.nativeApi?.unwatchImportFile();
    };
  }, [modal]);

  return null;
};
