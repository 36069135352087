/* tslint:disable */
/* eslint-disable */
/**
 * Befunde API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LabResultObservationRequest
 */
export interface LabResultObservationRequest {
    /**
     * 
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    'orderEntryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    'status': LabResultObservationRequestStatusEnum;
}

export const LabResultObservationRequestStatusEnum = {
    InProgress: 'IN_PROGRESS',
    PartialResult: 'PARTIAL_RESULT',
    EndResult: 'END_RESULT',
    OrderReceived: 'ORDER_RECEIVED',
    SpecimenReceived: 'SPECIMEN_RECEIVED'
} as const;

export type LabResultObservationRequestStatusEnum = typeof LabResultObservationRequestStatusEnum[keyof typeof LabResultObservationRequestStatusEnum];


