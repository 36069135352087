import React from 'react';
import { Avatar, Dropdown, Flex, Tag, theme, Typography } from 'antd';
import { LogoutOutlined, UserOutlined, WarningOutlined, FileAddOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { ItemType } from 'antd/es/menu/interface';

const { useToken } = theme;
const { Text } = Typography;

interface UserMenuProps {
  roleName?: string;
  username?: string;
  pictureUrl?: string;
  accountName: string;
  accountDisabled: boolean;
  userFullName: string;
  onUserAccountOpen: () => void;
  onLogout: () => void;
}

const useUserMenuClassNames = () => {
  const { token } = useToken();
  return {
    role: css`
      font-size: ${token.fontSizeSM}px;
    `,
    bold: css`
      font-weight: 500;
    `,
    innerWrapper: css`
      display: flex;
      gap: ${token.paddingXS}px;
      align-items: center;
      cursor: pointer;
    `,
    userIcon: css`
      font-size: ${token.fontSizeLG}px;
    `,
    name: css`
      text-align: right;
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: ${token.fontSizeSM}px;

      @media (max-width: ${token.screenLG}px) {
        display: none;
      }
    `,
    tag: css`
      margin: 0;
    `,
  };
};

export const UserMenu: React.FC<UserMenuProps> = ({
  roleName,
  username,
  pictureUrl,
  accountName,
  accountDisabled,
  userFullName,
  onUserAccountOpen,
  onLogout,
}) => {
  const styles = useUserMenuClassNames();
  const { token } = useToken();

  const pictureTitle = 'Sie können Ihr Profilbild unter https://gravatar.com konfigurieren';
  const items: ItemType[] = [
    {
      key: 'info',
      disabled: true,
      style: { cursor: 'default', whiteSpace: 'normal' },
      label: (
        <Flex vertical>
          <Text type="secondary" className={css(`margin-bottom: ${token.marginXS}px`)}>
            Eingeloggt als
          </Text>
          <Text className={styles.bold}>{userFullName}</Text>
          <Text className={styles.role} type="secondary">
            {roleName}
          </Text>
        </Flex>
      ),
    },
    { type: 'divider' },
    {
      key: 'app-switcher',
      icon: <FileAddOutlined />,
      onClick: () => (window.location.href = window._env_.LABRADOR_URL),
      label: 'Anforderungen',
      className: css`
        @media (min-width: ${token.screenMD}px) {
          display: none !important;
        }
      `,
    },
    {
      key: 'account',
      icon: <UserOutlined />,
      onClick: onUserAccountOpen,
      label: 'Benutzerkonto',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: onLogout,
      label: 'Abmelden',
    },
  ];

  return (
    <Dropdown
      menu={{ items: items }}
      trigger={['click']}
      arrow
      placement="bottomRight"
      overlayStyle={{ minWidth: 300, width: 300, maxWidth: 300 }}
    >
      <div className={styles.innerWrapper}>
        {pictureUrl ? (
          <div title={pictureTitle}>
            <Avatar className={styles.userIcon} src={pictureUrl} />
          </div>
        ) : (
          <UserOutlined className={styles.userIcon} title={pictureTitle} />
        )}
        <div className={styles.name}>
          <span>{username}</span>
          <br />
          <span>
            {accountDisabled && (
              <Tag color="warning" className={styles.tag} icon={<WarningOutlined />}>
                Deaktiviert
              </Tag>
            )}{' '}
            {accountName}
          </span>
        </div>
      </div>
    </Dropdown>
  );
};
