import React, { ReactNode } from 'react';
import logo from '../assets/logo.svg';
import { Alert, Badge, Button, Layout, theme } from 'antd';
import { HelpMenu } from './HelpMenu';
import { css } from '@emotion/css';
import { useAppVersionStore } from '../store/useAppVersionStore.ts';
import { Link, Route, Routes } from 'react-router';
import { AppSwitcher } from './AppSwitcher.tsx';
import { useThemeStore } from '../store/useThemeStore.ts';
import { LeftOutlined } from '@ant-design/icons';

const { useToken } = theme;
const { Header } = Layout;

const envTag: string = window._env_.ENV_TAG;

interface AppHeaderProps {
  userActions: ReactNode;
  topMenu: ReactNode;
}

const useAppHeaderClassNames = () => {
  const { token } = useToken();

  return {
    header: css`
      display: flex;
      position: relative;
      box-sizing: border-box;
      padding-inline: ${token.paddingLG}px !important;
    `,
    logo: css`
      img {
        width: 200px;
      }

      @media (max-width: ${token.screenLG}px) {
        img {
          width: 150px;
        }
      }
    `,
    conditionalLogo: css`
      display: block;
      @media (max-width: ${token.screenLG}px) {
        display: none;
      }
    `,
    backButton: css`
      padding: 0;
      animation: slideInFromLeft 0.4s ease-in-out;
      animation-iteration-count: 1;

      @keyframes slideInFromLeft {
        0% {
          transform: translateX(-150%);
        }
        100% {
          transform: translateX(0);
        }
      }
    `,
    conditionalBackButton: css`
      display: none;
      align-items: center;
      @media (max-width: ${token.screenLG}px) {
        display: flex;
      }
    `,
    content: css`
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    versionAlert: css`
      background-color: ${token.colorError};
      border: none;
      color: white;

      @media (max-width: ${token.screenLG}px) {
        display: none;
      }

      .ant-btn {
        margin-left: ${token.marginSM}px;
        animation: pulsate 3s ease-out;
        animation-iteration-count: infinite;
        opacity: 1;

        @keyframes pulsate {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      }
    `,
    actions: css`
      line-height: initial;
      display: flex;
      align-items: center;
      gap: ${token.paddingLG}px;

      @media (max-width: ${token.screenLG}px) {
        gap: ${token.paddingSM}px;
      }
    `,
  };
};

const Logo = () => {
  const styles = useAppHeaderClassNames();
  const { theme: befundeTheme } = useThemeStore();

  return (
    <Link to="/" className={styles.logo}>
      {envTag && envTag !== 'prod' ? (
        <Badge.Ribbon text={envTag} color="volcano">
          <img src={befundeTheme.logoData ? befundeTheme.logoData : logo} alt="labcomplete Befunde" />
        </Badge.Ribbon>
      ) : (
        <img src={befundeTheme.logoData ? befundeTheme.logoData : logo} alt="labcomplete Befunde" />
      )}
    </Link>
  );
};

export const AppHeader: React.FC<AppHeaderProps> = ({ userActions, topMenu }) => {
  const { bannerVisible } = useAppVersionStore();
  const styles = useAppHeaderClassNames();

  return (
    <Header className={styles.header}>
      <Routes>
        <Route
          path="/:id"
          element={
            <>
              <div className={styles.conditionalLogo}>
                <Logo />
              </div>
              <div className={styles.conditionalBackButton}>
                <Link to="/">
                  <Button icon={<LeftOutlined />} type="link" size="large" className={styles.backButton}>
                    Befunde
                  </Button>
                </Link>
              </div>
            </>
          }
        />
        <Route path="*" element={<Logo />} />
      </Routes>

      <div className={styles.content}>
        {bannerVisible() ? (
          <Alert
            message={<strong>Neue Version verfügbar!</strong>}
            type="error"
            action={<Button onClick={() => window.location.reload()}>Jetzt aktualisieren</Button>}
            className={styles.versionAlert}
          />
        ) : (
          <AppSwitcher />
        )}
      </div>
      <div className={styles.actions}>
        {topMenu}
        {userActions}
        <HelpMenu />
      </div>
    </Header>
  );
};
