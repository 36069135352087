import React, { useEffect } from 'react';
import { App, Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ThemeForm } from './ThemeForm.tsx';
import { useForm } from 'antd/es/form/Form';
import { Theme, ThemeUpdateRequest } from '../../../generated/openapi/befunde-api';
import { useUpdateTheme } from '../../../api/theme.queries.ts';

export const UpdateThemeModal: React.FC<{
  onClose: () => void;
  theme: Theme | null;
}> = ({ onClose, theme }) => {
  const { mutate, isPending } = useUpdateTheme();
  const [form] = useForm<ThemeUpdateRequest>();
  const { message } = App.useApp();

  const update = async (request: ThemeUpdateRequest) => {
    mutate(
      {
        id: theme!.id,
        themeUpdateRequest: request,
      },
      {
        onSuccess: () => {
          message.success('Theme wurde aktualisiert');
          closeAndReset();
        },
        onError: e => {
          message.error('Theme konnte nicht aktualisiert werden: ' + e);
        },
      }
    );
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: theme?.name ?? '',
      colorPrimary: theme?.colorPrimary ?? '',
      controlItemBgActive: theme?.controlItemBgActive ?? '',
      controlItemBgActiveHover: theme?.controlItemBgActiveHover ?? '',
      logoData: theme?.logoData ?? '',
    });
  }, [form, theme]);

  return (
    <Modal
      width={600}
      title="Theme bearbeiten"
      open={!!theme}
      destroyOnClose
      onCancel={closeAndReset}
      forceRender
      footer={[
        <Button key="cancel" onClick={closeAndReset}>
          Abbrechen
        </Button>,
        <Button key="submit" icon={<EditOutlined />} type="primary" loading={isPending} onClick={form.submit}>
          Speichern
        </Button>,
      ]}
    >
      <ThemeForm form={form} onFinish={update} />
    </Modal>
  );
};
