import React from 'react';
import { useGridClassNames } from '../OrderResult.styles.ts';
import { LabResultResponse } from '../../../generated/openapi/befunde-api';

export const SpecimenRenderer: React.FC<{ result: LabResultResponse }> = ({ result }) => {
  const { gridClassName, headerClassName, accreditationClassName, specimenClassName } = useGridClassNames();
  const specimen = result.observationSpecimen;

  if (!specimen) {
    return null;
  }

  return (
    <>
      <div className={gridClassName(0)}>
        <h3 className={headerClassName}>Probenherkunft</h3>
      </div>
      <div className={gridClassName(0)}>
        <div className={accreditationClassName}>{specimen.resultDistributionCategory}</div>
        <div className={specimenClassName}>
          {specimen.testName} {specimen.value}
        </div>
      </div>
    </>
  );
};
