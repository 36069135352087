/* tslint:disable */
/* eslint-disable */
/**
 * Befunde API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { RestError } from '../model';
// @ts-ignore
import type { Theme } from '../model';
// @ts-ignore
import type { ThemeCreateRequest } from '../model';
// @ts-ignore
import type { ThemeListResponse } from '../model';
// @ts-ignore
import type { ThemeUpdateRequest } from '../model';
/**
 * ThemeApi - axios parameter creator
 * @export
 */
export const ThemeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new theme
         * @param {ThemeCreateRequest} themeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTheme: async (themeCreateRequest: ThemeCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'themeCreateRequest' is not null or undefined
            assertParamExists('createTheme', 'themeCreateRequest', themeCreateRequest)
            const localVarPath = `/theme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(themeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all themes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllThemes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/theme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get theme by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTheme: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTheme', 'id', id)
            const localVarPath = `/theme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a theme
         * @param {string} id 
         * @param {ThemeUpdateRequest} themeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTheme: async (id: string, themeUpdateRequest: ThemeUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTheme', 'id', id)
            // verify required parameter 'themeUpdateRequest' is not null or undefined
            assertParamExists('updateTheme', 'themeUpdateRequest', themeUpdateRequest)
            const localVarPath = `/theme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(themeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThemeApi - functional programming interface
 * @export
 */
export const ThemeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThemeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new theme
         * @param {ThemeCreateRequest} themeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTheme(themeCreateRequest: ThemeCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Theme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTheme(themeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemeApi.createTheme']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all themes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllThemes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllThemes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemeApi.getAllThemes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get theme by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTheme(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Theme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTheme(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemeApi.getTheme']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a theme
         * @param {string} id 
         * @param {ThemeUpdateRequest} themeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTheme(id: string, themeUpdateRequest: ThemeUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Theme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTheme(id, themeUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThemeApi.updateTheme']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ThemeApi - factory interface
 * @export
 */
export const ThemeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThemeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new theme
         * @param {ThemeApiCreateThemeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTheme(requestParameters: ThemeApiCreateThemeRequest, options?: RawAxiosRequestConfig): AxiosPromise<Theme> {
            return localVarFp.createTheme(requestParameters.themeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all themes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllThemes(options?: RawAxiosRequestConfig): AxiosPromise<ThemeListResponse> {
            return localVarFp.getAllThemes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get theme by id
         * @param {ThemeApiGetThemeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTheme(requestParameters: ThemeApiGetThemeRequest, options?: RawAxiosRequestConfig): AxiosPromise<Theme> {
            return localVarFp.getTheme(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a theme
         * @param {ThemeApiUpdateThemeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTheme(requestParameters: ThemeApiUpdateThemeRequest, options?: RawAxiosRequestConfig): AxiosPromise<Theme> {
            return localVarFp.updateTheme(requestParameters.id, requestParameters.themeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTheme operation in ThemeApi.
 * @export
 * @interface ThemeApiCreateThemeRequest
 */
export interface ThemeApiCreateThemeRequest {
    /**
     * 
     * @type {ThemeCreateRequest}
     * @memberof ThemeApiCreateTheme
     */
    readonly themeCreateRequest: ThemeCreateRequest
}

/**
 * Request parameters for getTheme operation in ThemeApi.
 * @export
 * @interface ThemeApiGetThemeRequest
 */
export interface ThemeApiGetThemeRequest {
    /**
     * 
     * @type {string}
     * @memberof ThemeApiGetTheme
     */
    readonly id: string
}

/**
 * Request parameters for updateTheme operation in ThemeApi.
 * @export
 * @interface ThemeApiUpdateThemeRequest
 */
export interface ThemeApiUpdateThemeRequest {
    /**
     * 
     * @type {string}
     * @memberof ThemeApiUpdateTheme
     */
    readonly id: string

    /**
     * 
     * @type {ThemeUpdateRequest}
     * @memberof ThemeApiUpdateTheme
     */
    readonly themeUpdateRequest: ThemeUpdateRequest
}

/**
 * ThemeApi - object-oriented interface
 * @export
 * @class ThemeApi
 * @extends {BaseAPI}
 */
export class ThemeApi extends BaseAPI {
    /**
     * 
     * @summary Create a new theme
     * @param {ThemeApiCreateThemeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemeApi
     */
    public createTheme(requestParameters: ThemeApiCreateThemeRequest, options?: RawAxiosRequestConfig) {
        return ThemeApiFp(this.configuration).createTheme(requestParameters.themeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all themes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemeApi
     */
    public getAllThemes(options?: RawAxiosRequestConfig) {
        return ThemeApiFp(this.configuration).getAllThemes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get theme by id
     * @param {ThemeApiGetThemeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemeApi
     */
    public getTheme(requestParameters: ThemeApiGetThemeRequest, options?: RawAxiosRequestConfig) {
        return ThemeApiFp(this.configuration).getTheme(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a theme
     * @param {ThemeApiUpdateThemeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThemeApi
     */
    public updateTheme(requestParameters: ThemeApiUpdateThemeRequest, options?: RawAxiosRequestConfig) {
        return ThemeApiFp(this.configuration).updateTheme(requestParameters.id, requestParameters.themeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

